import React from 'react';

import FactCheck from './FactCheck';

function Home() {
  return (   
    <>
	  <FactCheck />
    </>
      
  );
}

export default Home;
